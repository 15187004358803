<template>
  <v-container>
    <v-row>
      <v-col :md="4" :cols="12">
        <v-card class="pa-5 ma-5" elevation="6">
          <v-card-title class="pb-5">$2 Day Pass</v-card-title>
          <v-card-text>
            Gain access to these stocks for 24 hours:
            <br />
            <ul>
              <li>$AMZN</li>
              <li>$AMD</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="loading1"
              :disabled="disabled"
              @click="
                loading1 = true;
                redirectToCheckout(stripe.small_one_time, payment);
              "
            >
              Select
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :md="4" :cols="12">
        <v-card class="pa-5 ma-5" elevation="12">
          <v-card-title class="pb-5">$4 Day Pass</v-card-title>
          <v-card-text>
            Gain access to these stocks for 24 hours:
            <br />
            <ul>
              <li>$AMZN</li>
              <li>$AMD</li>
              <li>$MSFT</li>
              <li>$TSLA</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="loading2"
              :disabled="disabled"
              @click="
                loading2 = true;
                redirectToCheckout(stripe.medium_one_time, payment);
              "
            >
              Select
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :md="4" :cols="12">
        <v-card class="pa-5 ma-5" elevation="20">
          <v-card-title class="pb-5">$6 Day Pass</v-card-title>
          <v-card-text>
            Gain access to these stocks for 24 hours:
            <br />
            <ul>
              <li>$AMZN</li>
              <li>$AMD</li>
              <li>$MSFT</li>
              <li>$TSLA</li>
              <li>$GOOG</li>
              <li>$APPL</li>
              <li>$ADBE</li>
              <li>$NVDA</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="loading3"
              :disabled="disabled"
              @click="
                loading3 = true;
                redirectToCheckout(stripe.large_one_time, payment);
              "
            >
              Select
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col :md="4" :cols="12">
        <v-card class="pa-5 ma-5" elevation="6">
          <v-card-title class="pb-5">$50/month</v-card-title>
          <v-card-text>
            Gain access to these stocks:
            <br />
            <ul>
              <li>$AMZN</li>
              <li>$AMD</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="loading4"
              :disabled="disabled"
              @click="
                loading4 = true;
                redirectToCheckout(stripe.small_monthly, subscription);
              "
            >
              Select
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :md="4" :cols="12">
        <v-card class="pa-5 ma-5" elevation="12">
          <v-card-title class="pb-5">$100/month</v-card-title>
          <v-card-text>
            Gain access to these stocks:
            <br />
            <ul>
              <li>$AMZN</li>
              <li>$AMD</li>
              <li>$MSFT</li>
              <li>$TSLA</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="loading5"
              :disabled="disabled"
              @click="
                loading5 = true;
                redirectToCheckout(stripe.medium_monthly, subscription);
              "
            >
              Select
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :md="4" :cols="12">
        <v-card class="pa-5 ma-5" elevation="20">
          <v-card-title class="pb-5">$150/month</v-card-title>
          <v-card-text>
            Gain access to these stocks:
            <br />
            <ul>
              <li>$AMZN</li>
              <li>$AMD</li>
              <li>$MSFT</li>
              <li>$TSLA</li>
              <li>$GOOG</li>
              <li>$APPL</li>
              <li>$ADBE</li>
              <li>$NVDA</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="loading6"
              :disabled="disabled"
              @click="
                loading6 = true;
                redirectToCheckout(stripe.large_monthly, subscription);
              "
            >
              Select
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <stripe-checkout
      ref="checkoutRef"
      :mode="mode"
      :pk="stripe.key"
      :customerEmail="store.user.email"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
    />
    <v-snackbar v-model="snackbar" timeout="10000">
      Please login before upgrading
    </v-snackbar>
  </v-container>
</template>

<script>
import { store } from "../store/store";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import stripeConfig from "../../stripeConfig.json";
export default {
  name: "Upgrade",
  components: {
    StripeCheckout,
  },
  data: () => ({
    store,
    stripe: {},
    mode: "subscription",
    email: "",
    subscription: "subscription",
    payment: "payment",
    lineItems: [],
    successURL: "",
    cancelURL: "",
    snackbar: false,
    disabled: false,
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false,
    loading6: false,
  }),
  created() {
    this.cancelURL = window.location.origin;
    this.successURL = window.location.origin + "/#/success";
    this.stripe =
      process.env.NODE_ENV === "production"
        ? stripeConfig.live
        : stripeConfig.test;
  },
  mounted() {
    if (this.store.user && this.store.user.email) {
      this.email = this.store.user.email;
    } else {
      this.snackbar = true;
      this.disabled = true;
    }
  },
  computed: {},
  methods: {
    redirectToCheckout(price, mode) {
      this.disabled = true;
      this.mode = mode;
      this.lineItems = [
        {
          price: price,
          quantity: 1,
        },
      ];
      this.$nextTick(() => {
        this.$refs.checkoutRef.redirectToCheckout();
      });
    },
  },
};
</script>